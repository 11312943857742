<template>
  <div class="elem-upcoming box-shadow radius"
  >
    <div class="hours">
      <span>{{event.date.start | moment('HH:mm a')}}</span>
      <span class="separ"></span>
      <span>{{event.date.end | moment('HH:mm a')}}</span>
    </div>
    <div class="right-upcom">
      <div class="text">
        <div class="title-upcoming">
          {{returnTitle()}}
        </div>
        <div class="candidate-upcoming">
          {{returnTalent()}}
        </div>
        <div class="desc-upcoming" v-html="event.description">
          <!-- {{event.description}} -->
        </div>
      </div>
      <div class="btn-green snall radius-small" v-if="event.processRef && processExists"
        @click="() => openEventModal(event)"
      >
        See Process
      </div>
    </div>

    <div class="global-param" >
      <div class="param ghost"></div>
      <div class="param" >
        <img src="@/assets/pictos/picto_param_purple.svg">
        <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
      </div>
      <div class="cont-param" >
        <div class="elem-param" @click="editEvent">Edit</div>
        <div class="elem-param red"
          @click="deleteEvent"
          v-if="this.$store.state.auth.type === 'Employee'"
        >
          Delete Event
        </div>
        <div class="elem-param red" @click="leaveEvent" v-else> Leave Event</div>
      </div>
    </div>

  </div>
</template>

<script>
import OfferProgress from '@/components/TalentComponents/offer/offerProgress.vue';
import ApplicantProcess from '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';
import EventEditModal from '@/components/UniversalComponents/Calendar/_eventEditModal.vue';

export default {
  name: 'EventView',
  props: ['event'],
  computed: {
    processExists() {
      if (this.$store.state.auth.type === 'Talent') {
        return this.$store.getters['talent/offer'](this.event.processRef.offer);
      }
      return this.$store.getters['company/applicant'](this.event.processRef.offer, this.event.processRef.applicant);
    },
  },
  methods: {
    returnTitle() {
      const title = this.event.title.split(' @ ');
      return title[0];
    },
    returnCompany() {
      const title = this.event.title.split(' @ ');
      return title[1];
    },
    returnTalent() {
      const title = this.event.title.split(' @ ');
      return title[1];
    },

    leaveEvent() {
      this.$store.dispatch('calendar/leaveEvent', this.event._id);
    },

    deleteEvent() {
      this.$store.dispatch('calendar/deleteEvent', this.event._id);
    },

    editEvent() {
      this.$modal.show(EventEditModal, {
        eventData: this.event,
      }, {
        name: 'event-edit-modal',
        height: 'auto',
        width: '50%',
        classes: ['overflow'],
      });
    },

    openEventModal(event) {
      if (this.$store.state.auth.type === 'Talent') {
        const offer = this.$store.getters['talent/offer'](this.event.processRef.offer);
        this.$modal.show(OfferProgress, {
          offer,
        }, {
          name: 'offer-process', height: 'auto', width: '70%', scrollable: true, adaptive: true, classes: ['overflow'],
        });
      } else if (this.$store.state.auth.type === 'Employee') {
        const applicant = this.$store.getters['company/applicant'](this.event.processRef.offer, event.processRef.applicant);
        this.$modal.show(ApplicantProcess,
          {
            applicant,
            processComp: ApplicantProcess,
            offerIdProp: this.event.processRef.offer,
          },
          {
            name: 'applicant-process',
            width: '80%',
            height: '70vh',
            scrollable: true,
            adaptive: true,
            classes: ['overflow'],
          });
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

.list-calendar{
  .elem-upcoming{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: calc(100% - 1vh);
    margin: 0;
    margin-bottom: 1vh;
    max-width: unset;
    position: relative;
    .hours{
      width: 20%;
      display:flex;
      flex-direction: column;
      border-radius: 0;
      border-bottom-left-radius: 1vh;
      border-top-left-radius: 1vh;
      span{
        &.separ{
          margin: 1vh 0;
        }
      }
    }
    .right-upcom{
      width: 80%;
      display: flex;
    }
    .text{
      margin: 0 1vh;
      width: 100%;
    }
    .btn-green{
      align-self: center;
      justify-self: flex-end;
      flex: 0 0 auto;
    }
  }
}
.red{
  color: crimson;
}
</style>
