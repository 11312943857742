<template>
  <div class="new-event-form">
    <div class="cont-form open">
      <div class="elem-form">
        <div class="cont-input name-input">
          <input class="v-select" :placeholder="'Title'" v-model="title" requiredVal="title" />
        </div>
      </div>
      <div class="elem-form open">
        <div class="cont-input description-input">
          <!-- <label> Description </label> -->
          <!-- <input class="v-select" :placeholder="'Description'" v-model="description" /> -->
          <wysiwyg class="radius box-shadow" v-model="description" requiredVal="description"  />
        </div>
      </div>
      <div class="cont-half">
        <div class="elem-form">
          <label>Start</label>
          <datetime
            class="elem-date v-select"
            type="time"
            v-model="dateStart"
            requiredVal="dateStart"
            :format="'MMMM d, yyyy, HH:mm '"
            :min-datetime="minDate"
            :max-datetime="maxDate"
            :minute-step="10"
            :use12-hour="true"
          />
        </div>
        <div class="elem-form">
          <label> End</label>
          <datetime
            class="elem-date v-select"
            type="time"
            v-model="dateEnd"
            requiredVal="dateEnd"
            :format="'MMMM d, yyyy, HH:mm '"
            :min-datetime="minDate"
            :max-datetime="maxDate"
            :minute-step="10"
            :use12-hour="true"
          />
        </div>
      </div>
      <div class="button-cont">
        <div class="btn-green radius" @click="submit">Submit</div>
        <div class="btn-green radius reverse" @click="close">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import validateMixin from '@/modules/mixins/validate';

const getDatesOfDay = (date) => {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  const end = new Date(date); // copy
  end.setHours(24, 0, 0, 0);
  return { start, end };
};

export default {
  name: 'EventForm',
  mixins: [validateMixin],
  props: ['closeForm', 'currentDate', 'eventData'],
  components: { Datetime },
  data() {
    return {
      title: '',
      description: '',
      dateStart: '',
      dateEnd: '',
      eventId: false,
    };
  },
  computed: {
    minDate() {
      if (!this.currentDate) {
        return null;
      }
      return getDatesOfDay(this.currentDate).start.toISOString();
    },
    maxDate() {
      if (!this.currentDate) {
        return null;
      }
      return getDatesOfDay(this.currentDate).end.toISOString();
    },
    date() {
      if (!this.currentDate) {
        return null;
      }
      return this.currentDate;
    },
  },
  methods: {
    submit() {
      this.error = false;
      this.sending = true;
      if (this.validateFields()) {
        const eventData = {
          title: this.title,
          description: this.description,
          date: {
            start: this.dateStart,
            end: this.dateEnd,
          },
        };

        if (this.eventId) {
          this.$store.dispatch('calendar/editEvent', { newEventData: eventData, eventId: this.eventId });
        } else {
          this.$store.dispatch('calendar/addEvent', eventData);
        }

        this.closeForm();
      } else {
        this.error = 'Please fill out all fields';
      }
    },
    close() {
      this.title = '';
      this.description = '';
      this.dateStart = '';
      this.dateEnd = '';
      this.closeForm();
    },

  },
  watch: {
    date() {
      this.close();
    },
  },

  mounted() {
    if (this.eventData) {
      this.title = this.eventData.title;
      this.description = this.eventData.description;
      this.dateStart = this.eventData.date.start;
      this.dateEnd = this.eventData.date.end;
      this.eventId = this.eventData._id;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.cont-form-date{
  // height: auto !important;
  // overflow: visible !important;
  .elem-form{
    .cont-input{
      width: 100%;
      input{
        width: calc(100% - 1vh);
      }
    }
    .vdatetime{
      margin-top: 0;
      input{
        width: calc(100% - 1vh);
      }
    }
  }
}

.button-cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .btn-green{
    margin-right: 1vh;
    &:last-child{
      margin-right: 0;
    }
  }
}

</style>
