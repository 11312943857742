<template>
<div id="event-form">
  <div class="green-circle-button close-button" @click="closeModal">
    <span>X</span>
  </div>
   <new-event-form :closeForm="this.closeModal"
    :eventData="this.eventData"
    />

    <div class="attendants">
      <h3>Attendants</h3>
      <div v-for="user in eventData.attendees" :key="user._id">
        <div class="pic-name">
        <div class="profile-pic"
          :class="user.profilePicture ? '' : 'empty'"
          :style=" user.profilePicture !== '' ? `background-image:url(${user.profilePicture})` : ''"
        >
        </div>
        <div class="profile-user">
          <div class="name-user"
          >
            {{userName(user)}}
          </div>

        </div>
      </div>
      </div>
    </div>
</div>

</template>

<script>
import NewEventForm from '@/components/UniversalComponents/Calendar/_newEventForm.vue';
// import PerfectScrollInit from '@/modules/mixins/perfectScrollInit';

export default {
  name: 'EventEditModal',
  props: ['eventData'],
  components: {
    NewEventForm,
  },

  methods: {
    userName(user) {
      return `${user.name.firstName} ${user.name.lastName}`;
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'event-edit-modal');
    },
  },

  mounted() {
    // PerfectScrollInit('#event-form');
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  #event-form{
    position: relative;
    padding: 2vh;
  }
  .pic-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .profile-pic {
      height: 4vh;
      width: 4vh;
      border-radius: 50%;
      background-color: $logo_color_dark;
      background-color: transparent;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      &.empty {
        background-color: $logo_color_dark;
      }
    }
    .profile-user {
      margin-left: 1vh;
      .name-user {
        font-size: $fnt-18;
      }
      .time-post {
        font-size: $fnt-12;
        color: #4c4c4c;
      }
    }
  }

</style>
