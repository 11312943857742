<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Calendar
        </div>
      </div>
    </div>
    <div class="inner-container">
      <div class="flex-container flex-2-tier-sdlarge">
        <div class="elem-flex hspe flex-overflow-column">
          <div class="cont-full-calendar" v-if="this.$store.state.calendar.loaded">
            <vue-cal
              :disable-views="['years', 'year']"
              hide-view-selector
              active-view="month"
              class=""
              :events="events"
              @cell-click="handleDateClick"
              :time-from="7 * 60"
              :time-to="21 * 60"
              :selected-date="selectedDate"
              :timeCellHeight="100"
              :clickToNavigate = false
              :dblclickToNavigate = false
              :transitions = false
              events-on-month-view="short"
            />
            <div class="btn-green radius addevent small"
              v-if="!this.addingEvent && !isbeforetoday"
              @click="() => {this.addingEvent = true}"
            >
              Add Event
            </div>
          </div>
        </div>

        <div class="elem-flex mhspe flex-overflow-column">
          <div class="upcoming">
            <div
              class="subtitle-page wother"
            >
              {{this.selectedDate | moment('dddd, MMMM Do YYYY')}}

              <div class="other">
                <div class="btn-green radius-small potential small" v-if="!showPotential"
                  @click="getPotential"
                >
                  Show Suggested Dates
                </div>
                <div class="btn-green radius potential small" v-if="showPotential"
                  @click="hidePotential"
                >
                  Hide Suggested Dates
                </div>
              </div>
            </div>
            <div class="add-event radius" v-if="addingEvent">
              <new-event-form v-if="addingEvent" :closeForm="() => {this.addingEvent = false;}"
                :currentDate="this.selectedDate"
              />
            </div>
            <div class="cont-upcoming up-event"
              v-if="upcoming.length > 0 || upcomingPotential.length > 0"
            >
              <div
                class="list-calendar"
              >
                <event-view
                  v-for="event in upcoming"
                  :key="event._id"
                  :event="event"
                  :class="isdatepast(event) ? 'after' : 'before'"
                />
                <div
                  class="subtitle-page no-margin"
                  v-if="showPotential"
                >
                  Suggested Dates
                </div>
                <event-view
                  v-for="(event, index) in upcomingPotential"
                  :key="index"
                  :event="event"
                  :class="isdatepast(event) ? 'after' : 'before'"
                />
              </div>
            </div>
            <div class="cont-upcoming up-event" v-else>
              <p><i>No Upcoming Events</i></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
// import OfferProgress from '@/components/TalentComponents/offer/offerProgress.vue';
// import ApplicantProcess from
// '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';
import EventView from './_eventView.vue';
import NewEventForm from './_newEventForm.vue';

export default {
  name: 'CalendarView',
  components: {
    VueCal,
    EventView,
    NewEventForm,
  },
  data: () => ({
    selectedDate: null,
    loading: true,
    today: null,
    addingEvent: false,
    showPotential: false,
  }),
  computed: {
    ...mapState('user', ['userInfo']),
    events() {
      if (this.$store.state.calendar.loaded) {
        const events = this.$store.state.calendar.events.map((event) => {
          const newEvent = event;
          newEvent.start = new Date(event.date.start);
          newEvent.end = new Date(event.date.end);
          return newEvent;
        });
        if (this.potentialDates && this.showPotential) {
          return [...events, ...this.potentialDates];
        }
        return events;
      }
      return false;
    },
    upcoming() {
      return this.$store.getters['calendar/upcoming'](this.selectedDate);
    },
    potentialDates() {
      return this.$store.state.calendar.potentialDates;
    },
    upcomingPotential() {
      if (!this.showPotential) {
        return false;
      }
      return this.$store.getters['calendar/upcomingPotential'](this.selectedDate);
    },

    isbeforetoday() {
      const madate = new Date();
      madate.setDate(madate.getDate() - 1);
      if (this.selectedDate >= madate) {
        return false;
      }
      return true;
    },
  },
  updated() {
  },
  mounted() {
    this.$store.dispatch('user/getUserInfo');
    this.$store.dispatch('calendar/getCalendar');
    if (this.$store.state.auth.type === 'Talent') {
      this.$store.dispatch('talent/getOffers');
    } else {
      this.$store.dispatch('company/getOffers');
    }
    this.selectedDate = new Date();
    this.today = new Date();
  },
  methods: {
    handleDateClick(date) {
      this.selectedDate = date;
    },
    hidePotential() {
      this.showPotential = false;
    },

    getPotential() {
      this.showPotential = true;
      if (this.$store.state.auth.type === 'Talent') {
        this.$store.dispatch('calendar/getPotentialDatesTalent');
      } else {
        this.$store.dispatch('calendar/getPotentialDatesEmployee');
      }
    },

    isdatepast(event) {
      const now = new Date();
      if (new Date(event.end) <= now) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.list-calendar{
  width: calc(100% - 1vh);
  overflow-x: auto;
  padding: 0.5vh;
  padding-top: 0;
  // margin-top: 0.5vh;
  margin-bottom: 3vh;
  .subtitle-page{
    &.no-margin{
      margin-bottom: 1vh !important;
    }
  }
}
.addevent{
  display: inline-block;
  width: auto;
  position: absolute;
  top: 1.15vh;
  right: 0;
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.add-event{
  padding: 1vh;
  margin-top: 4.1vh;
  background-color: $new_bg_grey;
}

.cont-upcoming{
  margin-top: 2vh;
  &.up-event{
    margin-top: 4.1vh;
  }
}

.vuecal{
  box-shadow: none;
}
.vuecal__bg, .vuecal__body{
  overflow: visible;
}
.vuecal__menu, .vuecal__cell-events-count {
  background-color: $logo_color_dark;
  color: #ffffff;
  button{
    &:focus{
      outline: none;
    }
  }
}
.vuecal__title-bar {
  background-color: transparent;
  border: none;
  font-size: $fnt-subtitle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  button{
    margin: 0;
    font-family: 'MontserratBold';
    font-size: $fnt-subtitle;
    color: $text_color;
    outline: none;
    &:hover{
      outline: none;
      // text-decoration: underline;
      background-color: transparent;
      color: $green_color;
    }
    &:focus{
      outline: none;
    }
  }
}

.vuecal__weekdays-headings, .vuecal__all-day{
  padding: 0 !important;
  border: none;
}
.vuecal__cell--today, .vuecal__cell--current {
  background-color: rgba(240, 240, 255, 0.4);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
  background-color: rgba(235, 255, 245, 0.4);
}
.vuecal__cell--selected:before {
  border-color: rgba(66, 185, 131, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
  background-color: rgba(195, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
  background-color: rgba(136, 236, 191, 0.25);
}

.cont-full-calendar{
  width: 100%;
  height: 100%;
  position: relative;
}

.vuecal__cell{
  // width: calc(calc(100%) / 7) !important;
  width: calc(calc(100% - 3.5vh) / 7) !important;
  background-color: #ffffff;
  margin: 0 0.5vh 0.5vh 0;
  border: none;
  border-radius: 1vh !important;
  overflow: hidden;
  box-shadow: 0 0 0.5vh rgba(22,0,51, 0.15);
  border-top: 0.4vh solid #ffffff;
  cursor: pointer;
  &.vuecal__cell--day6, &.vuecal__cell--day7{
    background-color: #d8d8d8;
    border-top: 0.4vh solid #d8d8d8;
  }
  &.vuecal__cell--out-of-scope{
    border-top: 0.4vh solid #cccccc;
    background-color: #cccccc;
  }
  &::before{
    border: none !important;
  }
  &.vuecal__cell--selected{
    border-top: 0.4vh solid $logo_color;
    background-color: #ffffff !important;
    .vuecal__cell-content{
    }
  }
  .vuecal__cell-date{
    // position: absolute;
    // top: 0;
    // left: 50%;
    // transform: translateX(-50%);
    margin: 0.5vh auto 1vh auto;
    font-family: 'MontserratBold';
  }
  .vuecal__cell-events{

    .vuecal__event{
      margin-bottom: 0.2vh;
      &:last-child{
        margin-bottom: none;
      }
      color: $text_color;
      background-color: $green_color;

    }
    .potential{
        background-color: #cafae5;
      }
  }
}

.vuecal--month-view .vuecal__cell-content,
.vuecal--year-view .vuecal__cell-content,
.vuecal--years-view .vuecal__cell-content{
  justify-content: flex-start;
}

.upcoming{
  width: 100%;
  .subtitle-page{
    margin-top: 1vh;
  }
  .elem-date{
    font-size: $label_min_fs;
    font-family: 'MontserratBold';
    margin-top: 1vh;
  }
}

.left{
  .vuecal--month-view{
    display: none;
  }
  .event-view{
    &.before{
      display: none;
    }
  }
}
.right{
  .upcoming{
    margin-bottom: 2vh;
    .container-event-view{
      .date-container{
        height: 8vh;
        background-color: $logo_color_dark;
        color: #ffffff;
        flex-direction: row;
        .separ-date{
          background-color: $green_color;
          width: 5vh;
          height: 0.3vh;
        }
      }
    }
  }
}
</style>
